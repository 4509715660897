import React from 'react';

function Private() {
  return (
    <div>
      <h2>Private Page</h2>
      <p>This content is only visible to authenticated users.</p>
    </div>
  );
}

export default Private;
