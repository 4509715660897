import React from 'react';

function Public() {
  return (
    <div>
      <h2>Public Page</h2>
      <p>This content is visible to everyone.</p>
    </div>
  );
}

export default Public;
