import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

function Navigation({ isAuthenticated, setIsAuthenticated }) {
  const navigate = useNavigate();
  const userEmail = sessionStorage.getItem('userEmail'); // Dodaj tę linię

  const handleLogout = async () => {
    try {
      const response = await fetch('/auth-api/logout', {
        method: 'POST',
        credentials: 'include'
      });

      setIsAuthenticated(false);
      sessionStorage.removeItem('isAuthenticated');
      sessionStorage.removeItem('userEmail'); // Dodaj tę linię
      navigate('/');
    } catch (err) {
      console.error('Logout failed:', err);
      setIsAuthenticated(false);
      sessionStorage.removeItem('isAuthenticated');
      sessionStorage.removeItem('userEmail'); // Dodaj tę linię
      navigate('/');
    }
  };

  return (
    <Navbar bg="secondary-subtle" variant="secondary-subtle" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src="/glon.org.png"
            height="30px"
            className="d-inline-block align-top me-2"
            alt="MyApp Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Public</Nav.Link>
            {isAuthenticated && (
              <Nav.Link as={Link} to="/private">Private</Nav.Link>
            )}
          </Nav>
          <Nav>
            {!isAuthenticated ? (
              <>
                <Nav.Link as={Link} to="/register">Register</Nav.Link>
                <Nav.Link as={Link} to="/login">Login</Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link className="text-light me-3">
                  {userEmail}
                </Nav.Link>
                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
