import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Navigation from './components/Navigation';
import Register from './components/Register';
import Login from './components/Login';
import Public from './components/Public';
import Private from './components/Private';
import PasswordReset from './components/PasswordReset';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return sessionStorage.getItem('isAuthenticated') === 'true';
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch('/auth-api/check-session', {
          credentials: 'include' // Important for sending cookies
        });
        if (response.ok) {
          setIsAuthenticated(true);
        }
      } catch (err) {
        console.error('Session check failed:', err);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <Navigation isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
      <Container className="mt-4">
        <Routes>
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route path="/" element={<Public />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login"
            element={<Login setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route
            path="/private"
            element={
              isAuthenticated ? <Private /> : <Navigate to="/login" />
            }
          />
        </Routes>
      </Container>
    </BrowserRouter>
  );
}

export default App;
